<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="login">
      <ValidationProvider name="Email" rules="required|email">
        <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-account-circle"
          type="email"
          v-model="email"
          autocomplete="email"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <ValidationProvider name="Password" rules="required|min:5|max:40">
        <v-text-field
          id="password"
          label="Password"
          name="password"
          v-model="password"
          prepend-icon="mdi-account-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-space-between">
        <router-link to="/forgot-password" class="text-decoration-none text-uppercase">
          Forgot your password?
        </router-link>
        <v-btn type="submit" color="primary" :disabled="invalid">Login</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
import AuthService from '@/services/AuthService';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, max, min } from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);

export default {
  name: 'LoginForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
      showPassword: false
    };
  },
  methods: {
    async login() {
      const payload = {
        email: this.email,
        password: this.password
      };
      try {
        this.$store.dispatch('ui/loading', true);
        const login = await AuthService.login(payload);
        if (login.data && login.data.two_factor) {
          this.$router.push('/challange');
          this.$store.dispatch('ui/loading', false);
        } else {
          const authUser = await this.$store.dispatch('auth/getAuthUser');
          this.$store.dispatch('ui/loading', false);
          if (authUser) {
            this.$store.dispatch('ui/snackbarSuccess', 'You have logged in succesfully.');
            this.$store.dispatch('auth/setGuest', { value: 'isNotGuest' });
            this.$router.push('/dashboard');
          } else {
            const error = Error();
            error.name = 'Fetch User';
            throw error;
          }
        }
      } catch (error) {
        this.$store.dispatch('ui/loading', false);
        this.$store.dispatch('ui/snackbarError', 'Unable to login.');
      }
    }
  }
};
</script>
