<template>
  <v-main transition="false" fill-height style="background: #eee; height: 100vh" fluid>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-4">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <LoginForm />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import LoginForm from '@/components/LoginForm';

export default {
  name: 'LoginView',
  components: {
    LoginForm
  }
};
</script>
